import 'antd/dist/antd.less'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import useBoolean from '@vezham/ui/dist/contracts/hooks/useBoolean'
import useVStore from '@vezham/ui/dist/contracts/hooks/useVStore'
import {
  HQkeys,
  StateKeys,
  StoreKeys,
  TokenKeys
} from '@vezham/ui/dist/contracts/iam'
import { useTheme } from '@vezham/ui/dist/contracts/theme'
import { Vcolor, Vmode } from '@vezham/ui/dist/contracts/theme/interface'
import '@vezham/ui/dist/index.css'
import Loading from '@vezham/ui/dist/organisms/loading'

import { actionAuthenticationCreators } from '@store/authentication/actions'
import useBusinessVStore from '@store/hq/business/vstore'
import usePeopleVStore from '@store/hq/people/vstore'

import useDispatch from '@utils/dispatcher'

import '@/App.less'
import { AppRoutes, Routers } from '@/routes'

function App() {
  const searchParams = new URLSearchParams(window.location.search)
  const state = searchParams.get(StateKeys.KEY)
  let init_iam_account_by_code = searchParams.get(
    StateKeys.INIT_IAM_ACCOUNT_BY_CODE
  )
  let init_iam_business_by_code = searchParams.get(
    StateKeys.INIT_IAM_BUSINESS_BY_CODE
  )

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { value: isLoading, setValue } = useBoolean(true)
  const { setMode, setColor } = useTheme()

  const goToAccounts = (iam?: boolean) => {
    let param_iam = iam
      ? `&${StateKeys.KEY}=${StateKeys.NO_ACTIVE_IAM_BUSINESS}`
      : ''

    window.open(
      process.env.REACT_APP_BUSINESS_URL +
        `?${StoreKeys.REDIRECTED_FROM}=hq${param_iam}`,
      '_self'
    )
  }

  const init = async () => {
    let RTbusiness = useVStore.get(TokenKeys.BUSINESS_REFRESH_TOKEN)
    if (
      state == StateKeys.INIT_IAM_BUSINESS_BY_CODE &&
      init_iam_business_by_code
    ) {
      if (!RTbusiness) {
        usePeopleVStore.initByCode(init_iam_account_by_code)
        useBusinessVStore.initByCode(init_iam_business_by_code)
        navigate(AppRoutes.LAUNCHER_IAM)
        setValue(false)
        return
      }
    }

    if (RTbusiness) {
      await dispatch(actionAuthenticationCreators.listWorkspace())
        .then(data => {
          if (data.workspaceList && data.workspaceList[0]) {
            let workspace = data.workspaceList[0]
            useVStore.set(HQkeys.ACTIVE_WORKSPACE, workspace.name)
          }
          setValue(false)
          return
        })
        .catch(err => {
          console.log('hq | err: ' + err)
          navigate(AppRoutes.NotFound + `?state=no_active_workspace`) // wjdlz | NOTE: chk the flow & nav to business
          return
        })
    } else {
      goToAccounts(true)
      return
    }
  }

  useEffect(() => {
    init()
  }, [])

  // wjdlz | TODO: have to update it based on API response
  useEffect(() => {
    setMode(Vmode.SYSTEM)
    setColor(Vcolor.BLUE)
  }, [])

  return <>{isLoading ? <Loading /> : <Routers />}</>
}

export default App

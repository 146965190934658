import { useDispatch as useDispatchGeneric } from 'react-redux'
import { Action } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import { GlobalState } from '@store/authentication/contracts'

const useDispatch: () => ThunkDispatch<GlobalState, any, Action> =
  useDispatchGeneric

export default useDispatch

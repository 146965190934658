import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const Layout = lazy(
  () =>
    import(
      /* webpackChunkName: "StringCMS" */ '@pages/apps/stringCMS/components/layout'
    )
)

const Home = lazy(
  () =>
    import(
      /* webpackChunkName: "StringCMS.Home" */ '@pages/apps/stringCMS/home'
    )
)

const PageHome = lazy(
  () =>
    import(
      /* webpackChunkName: "StringCMS.Home" */ '@pages/apps/stringCMS/pageHome'
    )
)

const CollectionSettings = lazy(
  () =>
    import(
      /* webpackChunkName: "StringCMS.Collections.Settings" */ '@pages/apps/stringCMS/settings'
    )
)

const CollectionEntity = lazy(
  () =>
    import(
      /* webpackChunkName: "StringCMS.Collections.List" */ '@pages/apps/stringCMS/list'
    )
)

const CollectionEntityCreate = lazy(
  () =>
    import(
      /* webpackChunkName: "StringCMS.Collections.Entity.Create" */ '@pages/apps/stringCMS/create'
    )
)

const CollectionEntityUpdate = lazy(
  () =>
    import(
      /* webpackChunkName: "StringCMS.Collections.Entity.Update" */ '@pages/apps/stringCMS/update'
    )
)

const Routes: RouteObject[] = [
  {
    path: 'stringcms',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: 'pages/:pageId',
        element: <PageHome />
      },
      {
        path: 'pages/:pageId/collections/:collectionId/settings',
        element: <CollectionSettings />
      },
      {
        path: 'pages/:pageId/collections/:collectionId/entities',
        element: <CollectionEntity />
      },
      {
        path: 'pages/:pageId/collections/:collectionId/entities/create',
        element: <CollectionEntityCreate />
      },
      {
        path: 'pages/:pageId/collections/:collectionId/entities/:entityId',
        element: <CollectionEntityUpdate />
      }
    ]
  }
]

export default Routes

import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const Layout = lazy(
  () => import(/* webpackChunkName: "Store" */ '@pages/apps/store')
)
const Home = lazy(
  () =>
    import(/* webpackChunkName: "Store.Home" */ '@pages/apps/store/pages/home')
)
const AppProfile = lazy(
  () =>
    import(
      /* webpackChunkName: "Store.AppProfile" */ '@pages/apps/store/pages/appProfile'
    )
)
const CollectionsList = lazy(
  () =>
    import(
      /* webpackChunkName: "Store.Apps" */ '@pages/apps/store/pages/collectionsList'
    )
)

const Categories = lazy(
  () =>
    import(
      /* webpackChunkName: "Store.Categories" */ '@pages/apps/store/pages/categories'
    )
)
const CategoriesList = lazy(
  () =>
    import(
      /* webpackChunkName: "Store.CategoriesList" */ '@pages/apps/store/pages/categoriesList'
    )
)
const routes: RouteObject[] = [
  {
    path: '/apps/store',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: 'collections/:collectionId',
        element: <CollectionsList />
      },
      {
        path: 'collections/:collectionId/apps/:appId',
        element: <AppProfile />
      },
      {
        path: 'categories',
        element: <Categories />
      },
      {
        path: 'categories/:categoryId',
        element: <CategoriesList />
      },
      {
        path: 'categories/:categoryId/apps/:appId',
        element: <AppProfile />
      }
    ]
  }
]
export default routes

import { Action, ActionCreator, Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'

import { GlobalState } from '@store/authentication/contracts'
import { workspace } from '@store/hq/workspaces/actions'
import { Workspace } from '@store/hq/workspaces/contracts'

/***
 * Action Types for pages
 *  */
export enum AuthenticationActionTypes {
  WORKSPACE_LIST_REQUESTED = 'WORKSPACE_LIST_REQUESTED',
  WORKSPACE_LIST_SUCCESSFUL = 'WORKSPACE_LIST_SUCCESSFUL',
  WORKSPACE_LIST_FAILED = 'WORKSPACE_LIST_FAILED'
}

/**
 *
 * Actions List for pages
 */

export type WorkspaceListRequestedAction =
  Action<AuthenticationActionTypes.WORKSPACE_LIST_REQUESTED>
export interface WorkspaceListSuccessfulAction
  extends Action<AuthenticationActionTypes.WORKSPACE_LIST_SUCCESSFUL> {
  readonly workspaceList: Workspace[]
}
export type WorkspaceListFailedAction =
  Action<AuthenticationActionTypes.WORKSPACE_LIST_FAILED>

export type AuthenticationActions =
  | WorkspaceListRequestedAction
  | WorkspaceListSuccessfulAction
  | WorkspaceListFailedAction

/***
 *  Interface List  for  pages dispatches
 */

export interface AuthenticationDispatch {
  listWorkspace: ActionCreator<
    ThunkAction<
      Promise<WorkspaceListSuccessfulAction>,
      GlobalState,
      null,
      WorkspaceListSuccessfulAction
    >
  >
}

/**
 * Dispatch list for pages
 */

export const actionAuthenticationCreators: AuthenticationDispatch = {
  listWorkspace: () => {
    return async (
      dispatch: Dispatch
    ): Promise<WorkspaceListSuccessfulAction> => {
      const workspaceListRequestedAction: WorkspaceListRequestedAction = {
        type: AuthenticationActionTypes.WORKSPACE_LIST_REQUESTED
      }
      dispatch(workspaceListRequestedAction)
      try {
        const workspaceList: Workspace[] = await workspace.list({})
        const workspaceListSuccessfulAction: WorkspaceListSuccessfulAction = {
          type: AuthenticationActionTypes.WORKSPACE_LIST_SUCCESSFUL,
          workspaceList
        }
        return dispatch(workspaceListSuccessfulAction)
      } catch (error) {
        const workspaceListFailedAction: WorkspaceListFailedAction = {
          type: AuthenticationActionTypes.WORKSPACE_LIST_FAILED
        }
        dispatch(workspaceListFailedAction)
        return Promise.reject(error)
      }
    }
  }
}

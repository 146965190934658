import React from 'react'
import { Navigate, RouteObject } from 'react-router-dom'

import Blogs from './blogs'
import StringCMS from './stringCMS'

const routes: RouteObject[] = [
  {
    path: '/apps',
    children: [
      {
        index: true,
        element: (
          <Navigate to={'/apps/store'} state={window.location.pathname} />
        )
      },
      ...StringCMS,
      ...Blogs
    ]
  }
]

export default routes

import axios from 'axios'

import {
  AuthToken,
  DomainType,
  GrantType,
  RQrefreshToken,
  TokenType
} from '@vezham/ui/dist/contracts/iam'

const refresh = '/iam/v1/refresh'

export const iam = {
  refresh: async (rq: RQrefreshToken): Promise<AuthToken> => {
    return axios.post(
      refresh,
      {
        grant_type: GrantType.REFRESH_TOKEN,
        refresh_token: rq.refresh_token
      },
      {
        tokenType: TokenType.NO_TOKEN,
        domainType: DomainType.DC
      }
    )
  }
}

import { Reducer } from 'redux'

import {
  AuthenticationActionTypes,
  AuthenticationActions
} from '@store/authentication/actions'
import { Workspace } from '@store/hq/workspaces/contracts'

export interface AuthenticationState {
  workspaceList: Workspace[]
  activeWorkspace: Workspace
}

export const initialAuthenticationState: AuthenticationState = {
  workspaceList: [],
  activeWorkspace: null
}

export const AuthenticationReducer: Reducer<AuthenticationState> = (
  state: AuthenticationState = initialAuthenticationState,
  action: AuthenticationActions
) => {
  switch (action.type) {
    case AuthenticationActionTypes.WORKSPACE_LIST_SUCCESSFUL: {
      return {
        ...state,
        workspaceList: action.workspaceList,
        activeWorkspace: action.workspaceList[0]
      }
    }
    default: {
      return state
    }
  }
}

import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const Layout = lazy(
  () =>
    import(
      /* webpackChunkName: "Blogs" */ '@pages/apps/blogs/components/layout'
    )
)

const Home = lazy(
  () => import(/* webpackChunkName: "Blogs.Home" */ '@pages/apps/blogs/home')
)

const List = lazy(
  () => import(/* webpackChunkName: "Blogs.List" */ '@pages/apps/blogs/list')
)

const PostCreate = lazy(
  () =>
    import(/* webpackChunkName: "Blogs.Create" */ '@pages/apps/blogs/create')
)

const PostUpdate = lazy(
  () =>
    import(/* webpackChunkName: "Blogs.Update" */ '@pages/apps/blogs/update')
)

const Routes: RouteObject[] = [
  {
    path: 'blogs',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: 'folders/:folderId',
        element: <List />
      },
      {
        path: 'folders/:folderId/posts/create',
        element: <PostCreate />
      },
      {
        path: 'folders/:folderId/posts/:postId',
        element: <PostUpdate />
      }
    ]
  }
]

export default Routes

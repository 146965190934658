import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'

import { IS_PRODUCTION } from '@vezham/ui/dist/contracts/constants'

import { AuthenticationReducer } from '@store/authentication/reducers'

const store = configureStore({
  reducer: {
    authentication: AuthenticationReducer
  },
  middleware: [thunk],
  devTools: !IS_PRODUCTION
})

export default store

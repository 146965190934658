import axios from 'axios'

import { DomainType, TokenType } from '@vezham/ui/dist/contracts/iam'

import { RQlist, Workspace } from '../contracts'

const list = '/workspaces/v1/list'

export const workspace = {
  list: async (rq: RQlist): Promise<Workspace[]> => {
    return axios.get(list, {
      tokenType: TokenType.BUSINESS,
      domainType: DomainType.DC
    })
  }
}

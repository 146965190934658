import React, { Suspense, lazy } from 'react'
import { Navigate, RouteObject, useNavigate, useRoutes } from 'react-router-dom'

import Loading from '@vezham/ui/dist/organisms/loading'

import Apps from './apps'
import Settings from './settings'
import Store from './store'

export enum AppRoutes {
  Home = '/',
  NotFound = '/not-found',
  AppsHome = '/apps/:appApiHandle',
  LAUNCHER_IAM = '/launcher/iam'
}

const Layout = lazy(() => import(/* webpackChunkName: "Apps" */ '@pages/apps'))

const reRoute: RouteObject[] = [
  {
    path: AppRoutes.LAUNCHER_IAM,
    element: <Navigate to={AppRoutes.Home} />
  },
  {
    path: '/pages',
    element: (
      <Navigate to={AppRoutes.AppsHome.replace(':appApiHandle', 'stringcms')} />
    )
  }
]

let childrenRoutes: RouteObject[] = [].concat(reRoute, {
  element: <Layout />,
  children: [...Apps, ...Settings, ...Store]
})

const Home = lazy(() => import(/* webpackChunkName: "Home" */ '@pages/home'))
const NotFound = lazy(
  () =>
    import(
      /* webpackChunkName: "NotFound" */ '@vezham/ui/dist/organisms/notFound'
    )
)

export const Routers = () => {
  const navigate = useNavigate()

  return (
    <Suspense fallback={<Loading />}>
      {useRoutes([
        {
          path: AppRoutes.Home,
          children: [
            {
              index: true,
              element: <Home />
            },
            ...childrenRoutes
          ]
        },
        {
          path: '*',
          element: (
            <NotFound
              pathname={window.location.pathname}
              onClick={() => navigate(AppRoutes.Home)}
            />
          )
        }
      ])}
    </Suspense>
  )
}
